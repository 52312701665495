import { useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToHashElement = () => {
  const location = useLocation();

  const hashElement = useMemo(() => {
    const { hash } = location;
    const removeHashCharacter = (str) => {
      const result = str.slice(1);
      return result;
    };

    if (hash) {
      const element = document.getElementById(removeHashCharacter(hash));
      return element;
    } else {
      window.scrollTo(0, 0);
      return null;
    }
  }, [location]);

  useEffect(() => {
    if (hashElement) {
      const headerElement = document.getElementById('header');
      const headerHeight = headerElement ? headerElement.offsetHeight : 0;
      window.scrollTo({
        top:
          hashElement.getBoundingClientRect().top +
          window.scrollY -
          headerHeight,
        behavior: 'smooth',
      });
    }
  }, [hashElement]);

  return null;
};

export default ScrollToHashElement;
