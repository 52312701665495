import * as React from 'react';
import { Helmet as HelmetComponent } from 'react-helmet-async';
import { getCookie } from '@libs/cookie';

const GTMHelmet: React.FC<unknown> = () => (
  <HelmetComponent>
    {process.env.REACT_APP_GTM_ID && (
      <script
        nonce={getCookie(document.cookie || '', 'nonce_key')}
        id="gtmScript"
      >
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
        n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.REACT_APP_GTM_ID}');`}
      </script>
    )}
  </HelmetComponent>
);

export default GTMHelmet;
