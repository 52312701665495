import React from 'react';
import { Trans, TransProps } from 'react-i18next';

const FormatContent = ({
  i18nKey,
  components,
  ...props
}: TransProps<string>): React.ReactElement => (
  <Trans
    i18nKey={i18nKey}
    components={{
      b: <b className="font-bold" />,
      i: <i className="font-italic" />,
      br: <div className="mt-4 block" />,
      ...components,
    }}
    {...props}
  />
);

export default FormatContent;
