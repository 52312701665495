import * as React from 'react';
import { Helmet as HelmetComponent } from 'react-helmet-async';

interface Props {
  title?: string;
  ogUrl?: string;
  description?: string;
  keywords?: string;
  ogTitle?: string;
  ogImg?: string;
  ogSiteName?: string;
  ogType?: string;
  ogDescription?: string;
  ogLocale?: string;
  canonical?: string;
}

const Helmet: React.FC<Props> = ({
  title,
  description,
  ogUrl,
  keywords,
  ogTitle,
  ogImg,
  ogSiteName,
  ogType,
  ogDescription,
  ogLocale,
  canonical,
}: Props) => (
  <HelmetComponent
    title={title}
    meta={[
      { name: 'description', content: description },
      { name: 'keywords', content: keywords },
      {
        name: 'viewport',
        content:
          'width=device-width,' +
          'height=device-height, ' +
          'initial-scale=1.0, ' +
          'shrink-to-fit=no,' +
          'maximum-scale=1.0,' +
          'minimum-scale=1.0,' +
          'user-scalable=no',
      },
      { property: 'og:title', content: ogTitle || title },
      { property: 'og:url', content: ogUrl },
      { property: 'og:image', content: ogImg },
      { property: 'og:type', content: ogType },
      { property: 'og:site_name', content: ogSiteName },
      { property: 'og:description', content: ogDescription || description },
      { property: 'og:locale', content: ogLocale },
      {
        property: 'cymetrics-version',
        content: process?.env?.REACT_APP_VERSION,
      },
    ]}
    link={[{ rel: 'canonical', href: canonical || ogUrl }]}
  />
);

const defaultDescription = [
  'Cymetrics is a comprehensive cybersecurity assessment SaaS platform',
  'to help our client enhancing their visibility of the cyber exposures',
  'and managing the cyber risks in an agile and flexible way.',
].join(' ');

const keyWords = [
  'OneDegree,cymetrics,information security,cyber,cyber security,',
  'cybersecurity,cyber as a service,cybersecurity as a service,',
  'cyber security as a service,vulnerability assessment,va,',
  'vulnerability assessment as a service,va as a service,pen test,',
  'penetration test,pentest as a service,penetration test as a service,cybersecurity as a service,',
  'saas,application security,network security,blackbox testing,exposure assessment as a service,',
  'cyber score,ISO27001,GDPR,PCIDSS,HIPAA,資訊安全,資訊安全即服務,弱點評估,弱點評估即服務,滲透測試,',
  '滲透測試即服務,軟體即服務,網站安全,網路安全,黑箱測試,當天可提供報告,即時報告,即時報表,曝險服務,技術面合規',
].join('');

Helmet.defaultProps = {
  title: 'Cymetrics',
  ogUrl: 'https://cymetrics.io',
  keywords: keyWords,
  ogTitle: 'Cymetrics',
  ogImg: 'CyberSecurity',
  ogSiteName: 'Cymetrics.io',
  ogType: 'website',
  ogDescription: defaultDescription,
  description: defaultDescription,
  ogLocale: 'zh_tw',
  canonical: 'https://cymetrics.io',
};

export default Helmet;
