import React from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { LANGUAGE_CODE_EN_US } from '@constants/language';
import { getBrowserLocales } from '@libs/browserLocale';
import { BrowserRouter } from 'react-router-dom';
import { ScrollToHashElement } from '@/components/ScrollTo';
import { LocalizedRouter } from '@components/i18n';
import { Helmet, GTMHelmet } from '@components/Helmet';
import AppRoute from './AppRoute';
import './App.css';

gsap.registerPlugin(ScrollTrigger);

const helmetContext = {};

const metaData = {
  'zh-tw': {
    title: 'Cymetrics資安檢測-SaaS平台',
    keywords: 'SaaS, 供應鏈風險評估, 資安檢測',
    description:
      'Cymetrics 提供快速彈性的曝險評估. 弱點偵測以及滲透測試，僅需提供網址即能透過雲端平台進行資安檢測，降低時間與人力成本，並量化資安評級，高效率提升檢測頻率。',
  },
  'en-us': {
    title: 'Cymetrics Cybersecurity Testing - SaaS Platform',
    keywords: 'SaaS, supply chain risk assessment, cybersecurity testing',
    description:
      'Cymetrics offers rapid and flexible exposure assessments, vulnerability ' +
      'detection, and penetration testing. Simply providing ' +
      'a URL allows cybersecurity testing through a cloud platform, reducing ' +
      'time and labor costs while quantifying security ratings and efficiently increasing testing frequency.',
  },
};

function App() {
  const { i18n } = useTranslation();

  return (
    <LocalizedRouter
      RouterComponent={BrowserRouter}
      defaultLanguage={getBrowserLocales()?.[0] || LANGUAGE_CODE_EN_US}
    >
      <HelmetProvider context={helmetContext}>
        <Helmet
          ogTitle={metaData?.[i18n.language]?.title}
          keywords={metaData?.[i18n.language]?.keywords}
          description={metaData?.[i18n.language]?.description}
          ogDescription={metaData?.[i18n.language]?.description}
          ogLocale={i18n.language}
          ogUrl={`https://cymetrics.io/${i18n.language}/`}
        />
        <GTMHelmet />
        <AppRoute />
        <ScrollToHashElement />
      </HelmetProvider>
    </LocalizedRouter>
  );
}

export default App;
