export enum ArticleType {
  ALL = 'all',
  NEWS = 'news',
  EVENTS = 'events',
  REPORT = 'report',
}

export interface Article {
  id: string;
  uid: string | null;
  type: Array<ArticleType>;
  title?: string;
  date: string;
  outline?: string;
  lastPublicationDate?: Date;
  image?: string;
  sections?: Array<{
    title: string;
    content: Array<string | React.ReactNode>;
  }>;
  reportLinks?: Array<string>;
}

export interface ArticleList {
  totalPage: number;
  currentPage: number;
  list: Array<Article>;
}

type SpanType = ArticleSpans[number];

export interface SpanWithText extends SpanType {
  text: string;
}

export type ArticleSpans = Array<{
  start: number;
  end: number;
  type: string;
  data?: {
    // eslint-disable-next-line camelcase
    link_type?: string;
    target?: string;
    url?: string;
  };
}>;

export type ArticleListRetriver = (
  type: ArticleType,
  lang: string,
  page: number,
  pageSize?: number
) => Promise<ArticleList>;

export type ArticleContentRetriver = (
  id: string,
  lang?: string
) => Promise<Article | undefined>;

export type NextArticleRetriver = (
  type: ArticleType,
  id: string,
  lang: string
) => Promise<Article | undefined>;
