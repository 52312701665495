import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LocalizedContext } from './LocalizedContext';

interface Props {
  RouterComponent: React.ComponentClass<any>;
  defaultLanguage?: string;
  children: React.ReactNode;
}

export const LocalizedRouter: React.FC<Props> = ({
  children,
  RouterComponent,
  defaultLanguage,
}) => {
  const { i18n } = useTranslation();

  return (
    <RouterComponent>
      {/* ([a-zA-Z]{2}) for two code */}
      <Route path="/:lang([a-z]{2}-[A-Z]{2}|cimode)/:path(.*)">
        {({ match, location }) => {
          /**
           * Get current language
           * Set default locale to en if base path is used without a language
           */
          const params = match
            ? match.params
            : { lang: undefined, path: undefined };
          const { lang = defaultLanguage, path = '' } = params;

          /**
           * If language is not in route path, redirect to language root
           */
          const { pathname, search } = location;

          if (!pathname.includes(`/${lang}/`)) {
            return <Redirect to={`/${lang}${pathname}${search}`} />;
          } else if (lang !== i18n.language) {
            i18n.changeLanguage(lang);
          }

          /**
           * Return child elements
           */
          return (
            <LocalizedContext.Provider
              value={{
                lang,
                path,
              }}
            >
              {children}
            </LocalizedContext.Provider>
          );
        }}
      </Route>
    </RouterComponent>
  );
};
