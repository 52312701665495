import React from 'react';
import { Route, Switch } from 'react-router-dom';

import loadable from '@loadable/component';
// import NewMain from '@views/NewMain';
import { ArticleType } from './libs/cms/interfaces';

const NewMainPage = loadable(() => import('@pages/NewMainPage'));
const NewAboutUsPage = loadable(() => import('@pages/NewAboutUsPage'));
// const MainPage = loadable(() => import('@pages/MainPage'));
const PrivacyPolicyPage = loadable(() => import('@pages/PrivacyPolicyPage'));
const CookiePolicyPage = loadable(() => import('@pages/CookiePolicyPage'));
const TermOfUsePage = loadable(() => import('@pages/TermOfUsePage'));
const FAQPage = loadable(() => import('@pages/FAQPage'));
const EDMPage = loadable(() => import('@pages/EDM'));
const EDMWeb3 = loadable(() => import('@pages/EDMWebThree'));
const LatestPage = loadable(() => import('@pages/LatestPage'));
const ArticlePage = loadable(() => import('@pages/ArticlePage'));
// const AboutUsPage = loadable(() => import('@pages/AboutUsPage'));
const PartnerPage = loadable(() => import('@pages/PartnerPage'));
const NotFoundPage = loadable(() => import('@pages/NotFoundPage'));
const FreeRatingPage = loadable(() => import('@pages/FreeRatingPage'));
// const VASPage = loadable(() => import('@pages/products/VASPage'));
const ProcurementPage = loadable(() => import('@pages/ProcurementPage'));
const EASMPage = loadable(() => import('@pages/products/EASMPage'));
const MetaRedPage = loadable(() => import('@pages/products/MetaRedPage'));
const PTSPage = loadable(() => import('@pages/products/PTSPage'));
const TPCRMPage = loadable(() => import('@pages/products/TPCRMPage'));
const NewVASPage = loadable(() => import('@pages/products/NewVASPage'));
const NewPartnerPage = loadable(() => import('@pages/products/PartnerPage'));

const AppRoute = () => (
  <Switch>
    <Route
      path={`/:lang/latest/:type(${Object.values(ArticleType).join(
        '|'
      )})/:articleId`}
      component={ArticlePage}
    />
    <Route
      path={`/:lang/latest/:type(${Object.values(ArticleType).join('|')})`}
      component={LatestPage}
    />
    {/* <Route
      path="/:lang/products/cymetrics-vulnerabilityassessment"
      component={VASPage}
    /> */}
    <Route path="/:lang/products/easm" component={EASMPage} />
    <Route path="/:lang/products/metared" component={MetaRedPage} />
    <Route path="/:lang/products/pts" component={PTSPage} />
    <Route path="/:lang/products/ai-redteam" component={TPCRMPage} />
    <Route path="/:lang/partner" component={NewPartnerPage} />
    <Route path="/:lang/products/vas" component={NewVASPage} />
    <Route path="/:lang/cymetics-facettech" component={EDMPage} />
    <Route path="/:lang/cymetrics-metared" component={EDMWeb3} />
    <Route path="/:lang/free-rating" component={FreeRatingPage} />
    <Route path="/:lang/latest" exact component={LatestPage} />
    <Route path="/:lang/about" component={NewAboutUsPage} />
    <Route path="/:lang/faq">
      <FAQPage />
    </Route>
    <Route path="/:lang/terms-of-use">
      <TermOfUsePage />
    </Route>
    <Route path="/:lang/cookies">
      <CookiePolicyPage />
    </Route>
    <Route path="/:lang/privacy-policy">
      <PrivacyPolicyPage />
    </Route>
    <Route path="/:lang/partner">
      <PartnerPage />
    </Route>
    <Route path="/:lang/gov-procurement">
      <ProcurementPage />
    </Route>
    <Route path="/:lang" exact>
      {/* <MainPage /> */}
      <NewMainPage />
    </Route>
    <Route path="/:lang/new">
      <NewMainPage />
    </Route>
    <Route path="/" exact>
      <NewMainPage />
      {/* <MainPage /> */}
    </Route>
    <Route component={NotFoundPage} />
  </Switch>
);

export default AppRoute;
