function getCookie(cookie: string, name: string): string | null {
  const q = {};
  cookie
    ?.replace(/\s/g, '')
    .split(';')
    .map((i) => i.split('='))
    .forEach(([key, value]) => {
      q[key] = value;
    });
  return q[name] ?? null;
}

export { getCookie };
