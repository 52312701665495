import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

import { LANGS_NAME_BY_CODE, LANGUAGE_CODE_EN_US } from '@constants/language';

import { getBrowserLocales } from './browserLocale';

/*
  TODO: decide to load translation data in browser or preload in project
  current is use http loading in browser to provide error in CI.
*/
// import translationEN from '@public/static/locales/en-us/new_landing.json';
// import translationZhHK from '@public/static/locales/zh-hk/new_landing.json';

// const resources = {
//   [LANGUAGE_SHORT_CODE_EN]: {
//     translation: translationEN,
//   },
//   [LANGUAGE_SHORT_CODE_ZH]: {
//     translation: translationZhHK,
//   }
// };

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    // resources,
    backend: {
      loadPath: '/static/locales/{{lng}}/{{ns}}.json',
      allowMultiLoading: false,
    },
    lng: getBrowserLocales()?.[0] || LANGUAGE_CODE_EN_US, // if you're using a language detector, do not define the lng option
    fallbackLng: [...Object.keys(LANGS_NAME_BY_CODE), 'cimode'],
    defaultNS: 'landing-revamp',
    ns: ['landing-revamp', 'new_landing'],

    // load: 'currentOnly', // if using language like zh-TW, zh-HK, will need to turn this on
    lowerCaseLng: true,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: true,
    },
  });

export default i18n;
