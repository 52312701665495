/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from '@/App';
import reportWebVitals from '@/reportWebVitals';
import './libs/i18n';
import { LandingAPI } from '@cymetrics/api';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import 'windi.css';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  // inspect({
  //   url: 'https://statecharts.io/inspect',
  //   iframe: false,
  // });
} else {
  Sentry.init({
    dsn: 'https://cb46547aed204f7da47b54c5cea99289@o179653.ingest.sentry.io/5415933',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.8 : 1.0,
    environment: process.env.REACT_APP_ENV || 'test',
  });
}

LandingAPI.setInstanceUrl(process.env.REACT_APP_API_URL);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
