import { shortCodeMap, LANGS_NAME_BY_CODE } from '@constants/language';

export const getBrowserLocales = (options = {}): string[] => {
  const languageCodes = Object.keys(LANGS_NAME_BY_CODE);
  const defaultOptions = {
    languageCodeOnly: false,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales
    .filter((locale) =>
      languageCodes.includes(
        shortCodeMap[locale.toLowerCase()] || locale.toLowerCase()
      )
    )
    .map((locale) => {
      const trimmedLocale = locale.trim();

      return opt.languageCodeOnly
        ? trimmedLocale.split(/[_-]/)[0]
        : shortCodeMap[locale.toLowerCase()] || locale.toLowerCase();
    });
};
