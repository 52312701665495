const LANGUAGE_CODE_EN_US = 'en-us';
const LANGUAGE_CODE_ZH_HK = 'zh-hk';
const LANGUAGE_CODE_ZH_TW = 'zh-tw';

const LANGUAGE_SHORT_CODE_EN = 'en';
const LANGUAGE_SHORT_CODE_ZH = 'zh';

const shortCodeMap = {
  [LANGUAGE_SHORT_CODE_ZH]: LANGUAGE_CODE_ZH_TW,
  [LANGUAGE_SHORT_CODE_EN]: LANGUAGE_CODE_EN_US,
};

const fullCodeMap = {
  [LANGUAGE_CODE_ZH_TW]: LANGUAGE_SHORT_CODE_ZH,
  [LANGUAGE_CODE_EN_US]: LANGUAGE_SHORT_CODE_EN,
};

const LANGS_NAME_BY_CODE = {
  [LANGUAGE_CODE_EN_US]: 'EN',
  [LANGUAGE_CODE_ZH_TW]: '中文',
};

export {
  LANGUAGE_CODE_EN_US,
  LANGUAGE_CODE_ZH_HK,
  LANGUAGE_CODE_ZH_TW,
  LANGUAGE_SHORT_CODE_EN,
  LANGUAGE_SHORT_CODE_ZH,
  shortCodeMap,
  fullCodeMap,
  LANGS_NAME_BY_CODE,
};
